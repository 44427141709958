import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import { Flex, Box, Grid } from 'src/components/FlexBox';
import SmartButton from 'src/components/SmartButton';
import Text from 'src/components/Text';
import IconPreset from 'src/components/IconPreset';
import Frame from 'src/components/Frame';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { useResponsiveValue } from 'src/components/Breakpoints';

const variants = {
  small: 'Small',
  large: 'Large',
};

const floorscale = (x, s) => Math.floor(x / s) * s;

const LogoListModule = ({
  brands,
  button,
  caption,
  subcaption,
  disablePaddingTop,
  disablePaddingBottom,
  variant,
  ...other
}) => {
  const count = useResponsiveValue(
    [2, 3, 4, 5].map((n) => floorscale(brands.length, n))
  );

  const displayedBrands = brands.slice(0, count);

  return (
    <ModuleWrapper
      {...other}
      flexDirection="column"
      css={css({
        py: 'sectionMargin',
        px: 'pageMargin',
        paddingTop: disablePaddingTop ? 0 : 'sectionMargin',
        paddingBottom: disablePaddingBottom ? 0 : 'sectionMargin',
      })}
    >
      <Flex gy={4} flexDirection="column">
        {caption && (
          <Text
            textAlign="center"
            variant={variant === variants.small ? 'heading.s' : 'heading.l'}
          >
            {caption}
          </Text>
        )}
        {subcaption && (
          <Text
            textAlign="center"
            variant={variant === variants.small ? 'heading.xs' : 'heading.m'}
          >
            {subcaption}
          </Text>
        )}
        <Box>
          <Grid
            gridTemplateColumns={[2, 3, 4, 5].map((n) => `repeat(${n}, 1fr)`)}
            justifyContent="center"
            gridGap={variant === variants.small ? 4 : 2}
            css={css({
              margin: '0 auto',
              width: '100%',
              maxWidth: variant === variants.small ? '70rem' : 'none',
            })}
          >
            {displayedBrands.map(({ id, ...rest }) => (
              <Frame
                key={id}
                ratio={variant === variants.large ? 2 / 3 : false}
              >
                <Box
                  css={css({
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 1,
                    color: variant === variants.large ? 'mode' : 'gray400',
                    bg: variant === variants.large ? 'modeAlpha.1' : 'none',
                  })}
                >
                  <IconPreset {...rest} />
                </Box>
              </Frame>
            ))}
          </Grid>
        </Box>
        {button && <SmartButton {...button} />}
      </Flex>
    </ModuleWrapper>
  );
};

export default LogoListModule;

export const query = graphql`
  fragment LogoListModuleFragment on ContentfulLogoListModule {
    id
    slug
    caption
    variant
    colorMode
    background {
      ...ImageGroupFragment
    }
    disablePaddingTop
    disablePaddingBottom
    brands {
      id
      identifier
      media {
        file {
          url
        }
      }
    }
    button {
      ...ButtonFragment
    }
  }
`;
